import React, { useState,useRef , useEffect} from 'react';
import {Link} from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';
import AboutUs from './aboutUs';
import UserProfile from './userProfile';
import UserMessages from './userMessages';
import Subscription from './Subscription';
import logo from '../images/logo.jpeg';
import axios from 'axios';
import ErrorMessage from './HomePage/ErrorMessage';
import NotificationPanel from './NotificationPanel';


const NavBar = ({isNewTagsVisible,setIsNewTagsVisible}) => {
    const [isCreatedNewAccountVisible, setCreatedAccountVisible]=useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [isEnterOTPVisible, setEnterOTPVisible]=useState(false);
    const [isForgotPasswordVisible, setForgotPasswordVisible]=useState(false);
    const [isEnterOTPVisibleForgotPass,setEnterOTPVisibleForgotPass]=useState(false);
    const [isEnterPasswordVisibleForgotPass,setEnterPasswordVisibleForgotPass]=useState(false);
    const [isUserProfileVisible,setUserProfileVisible]=useState(false);
    const [isUserMessagesVisible,setUserMessagesVisible]=useState(false);
    const [profilePicture, setProfilePicture] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [forgotEmail, setForgotEmail]=useState('');
    const [forgotEmailSaved,setForgotEmailSaved]=useState('');
    const [forgotPasswordEmailError,setForgotPasswordEmailError]=useState('');
    const [forgotPasswordOTPError, setForgotPasswordOTPError]=useState('');
    const [newPassword,setNewPassword]=useState('');
    const [newPasswordError,setNewPasswordError]=useState('');

    const [otp,setOTP]=useState('');
    const [enteredOTP,setEnteredOTP]=useState('');
    const [signUpOTPError,setsignUpOTPError]=useState('');

    const [logInEmail, setLogInEmail] = useState('');
    const [logInPassword, setLogInPassword] = useState('');
    const [logInEmailError, setLogInEmailError] = useState('');
    const [logInPasswordError,setLogInPasswordError]=useState('');

    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpEmailError, setSignUpEmailError] = useState('');
    const [signUpPasswordError, setSignUpPasswordError]=useState('');

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [activeLink, setActiveLink] = useState('home');

    const [isAboutUsVisible,setIsAboutUsVisible]=useState(false);

    const [verifyButtonDisabled, setVerifyButtonDisables]=useState(true);

    const [isSubscriptionVisible,setSubscriptionVisible]=useState(false);

    const [errorMessage,setErrorMessage]=useState('');

    const menuRef = useRef(null);

    

    const navigate=useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSetActive = (link) => {
        if(link==="about"){
            setIsAboutUsVisible(true);
            return;
        }
        if(link==="subscription"){
            setSubscriptionVisible(!isSubscriptionVisible);
            return;
        }
        setActiveLink(link);
        navigate({
            pathname: "/"
        });
        setShowLinks(false)
    };

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
      
      useEffect(() => {
        if (isDropdownOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isDropdownOpen]);


      const isSubscribedByGoogle= async (storedLoggedInEmail) =>{
        try{
            const response = await fetch('/api/isAutoSubscribed', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({storedLoggedInEmail})
            });
            const data = await response.json();
            if(data.message==='subscribed'){
                localStorage.setItem('subscribedByGoogle',true);
            }else{
                localStorage.setItem('subscribedByGoogle',false);
            }
        } catch (error) {}
    }

    const getUser = async () => {
        try {
            const response = await axios.get("/login/sucess", { withCredentials: true });
            if(response.data.message==="user Login"){
                localStorage.setItem('isLoggedInByGoogle',true);
                localStorage.setItem('loggedInEmailGoogle',response.data.user[0]);
                isSubscribedByGoogle(response.data.user[0]);
                if (!localStorage.getItem("isreloaded")) {
                    localStorage.setItem("isreloaded",true);
                    window.location.reload();
                }
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        getUser();
    }, [])


    const handleLogout = () => {
        setIsDropdownOpen(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('loggedInEmail');
        localStorage.removeItem('loggedInPassword');
        localStorage.removeItem('subscribed'); 
        localStorage.removeItem('isLoggedInByGoogle');
        localStorage.removeItem('loggedInEmailGoogle');
        localStorage.removeItem("isreloaded");
        localStorage.removeItem("subscribedByGoogle");
        localStorage.removeItem("subscriptionDetail");
        localStorage.removeItem("preferredCurrency");
        localStorage.removeItem("languageDetail");
        window.open("/logout","_self");
    };


    useEffect(() => {
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        const storedIsLoggedInByGoogle=localStorage.getItem('isLoggedInByGoogle');

        if (storedIsLoggedIn === 'true' || storedIsLoggedInByGoogle) {
            const storedLoggedInEmail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
            if(storedIsLoggedIn==="true"){
                const storedLoggedInPassword = localStorage.getItem('loggedInPassword');
                const handelAutoSign= async () =>{
                    try{
                        const response = await fetch('/api/autoLogIn', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({storedLoggedInEmail,storedLoggedInPassword})
                        });
                        const data = await response.json();
                        if(data.message==='login successfull'){
                            isSubscribed();
                        }
                    } catch (error) {

                    }
                }
                handelAutoSign();
            }
            const loadProfilePicture= async ()=>{
                try{
                    const response = await fetch('/api/loadUserData', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({storedLoggedInEmail})
                    });
                    const data = await response.json();
                    if(data.username!=null){
                        localStorage.setItem("username",data.username);
                    }
                    if(data.profilePicture!=null){
                        const file = `/uploads/${data.profilePicture}`;
                       setProfilePicture(file);
                    }if(data.subscription!=null){
                        localStorage.setItem("subscriptionDetail",data.subscription);
                    }if(data.preferredCurrency!=null){
                        localStorage.setItem("preferredCurrency",data.preferredCurrency);
                    }
                }catch (error) {}
            }

            const isSubscribed= async () =>{
                try{
                    const response = await fetch('/api/isAutoSubscribed', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({storedLoggedInEmail})
                    });
                    const data = await response.json();
                    if(data.message==='subscribed'){
                        localStorage.setItem('subscribed',true);
                    }else{
                        localStorage.setItem('subscribed',false);
                    }
                } catch (error) {}
            }
            loadProfilePicture();
        }
      }, []);

    
    const toggleLinks = () => {
        setShowLinks(!showLinks);
      };

    const handleSubmitForSignUp = async (event) =>{
        event.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(signUpEmail)) {
            setSignUpEmailError('Please enter a valid email address.');
        }
        else if(signUpPassword.length < 8 || signUpPassword.length > 13 ){
            setSignUpPasswordError('Password must be between 8 and 13 characters long.');
        }
         else {
            event.preventDefault();
            setCreatedAccountVisible(false);
            setEnterOTPVisible(true);
            try{
                const response = await fetch('/api/verifyAccountExistAndSendingOTP', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({signUpEmail,signUpPassword})
                });
                const data = await response.json();
                if(data.message==='otp send successfully'){
                    //<-- Positive Message should be added -->
                    setOTP(data.otp);
                }
            } catch (error) {}
            setSignUpEmailError('');
            setSignUpPasswordError('');
        }
    };

    const handleSubmitForForgotPassword=async (event)=>{
        event.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(forgotEmail)) {
            setForgotPasswordEmailError('Please enter a valid email address.');
        }
         else {
            event.preventDefault();
        try{
                setForgotEmailSaved(forgotEmail);
                setForgotPasswordVisible(false);
                setEnterOTPVisibleForgotPass(true);
                setForgotPasswordEmailError('');
                const response = await fetch('/api/verifyAccountExistAndSendingOTPForForgotPassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({forgotEmail})
                });
                const data = await response.json();
                if(data.message==='otp send successfully'){
                    //<-- positive message should be added  -->
                    setOTP(data.otp);
                }else{
                    setForgotPasswordEmailError(data.message);
                }
            } catch (error) {}
        }        
    }

    const handleSubmitOTPForForgotPsssword=async (event)=>{
        event.preventDefault();
        if(enteredOTP===otp){
            setForgotPasswordOTPError(``);
            setEnterOTPVisibleForgotPass(false);
            setEnterPasswordVisibleForgotPass(true);
        }else{
            setForgotPasswordOTPError(`Entered OTP is incorrect`);
        }
    }

    const handleSubmitForNewPassword=async (event)=>{
        event.preventDefault();
        if(newPassword.length < 8 || newPassword.length > 13 ){
            setNewPasswordError('Password must be between 8 and 13 characters long.');
        }
         else {
            event.preventDefault();
        try{
                const response = await fetch('/api/ForgotPassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({forgotEmailSaved,newPassword})
                });
                const data = await response.json();

                if(data.message==='Password reset successfully'){
                    setEnterPasswordVisibleForgotPass(false);
                    setForgotEmailSaved('');
                }else{
                    setNewPasswordError(data.message);
                }
            } catch (error) {}
            setSignUpEmailError('');
            setSignUpPasswordError('');
        }
    }

    const handleSubmitOTP = async (event) =>{
        event.preventDefault();
        if(enteredOTP===otp){
            setsignUpOTPError(``);
            try{
                const response = await fetch('/api/createAccount', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({signUpEmail,signUpPassword})
                });
                const data = await response.json();
                
                if(data.message==='Account created successfully'){
                    setEnterOTPVisible(false);
                    setCreatedAccountVisible(false);
                }
            }catch (error) {}
        }else{
            setsignUpOTPError(`Entered OTP is incorrect`);
        }
    }

    const isSubscribed= async () =>{
        try{
            const response = await fetch('/api/isSubscribed', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({logInEmail})
            });
            const data = await response.json();
            if(data.message==='subscribed'){
                localStorage.setItem('subscribed',true);
            }else{
                localStorage.setItem('subscribed',false);
            }
            window.location.reload();
        } catch (error) {}
    }

    const handleSubmitForSignIn = async (event) => {
        event.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(logInEmail)) {
            setLogInEmailError('Please enter a valid email address.');
            setLogInPasswordError('');
        }
        else if(logInPassword.length < 8 || logInPassword.length > 13 ){
            setLogInPasswordError('Password must be between 8 and 13 characters long.');
            setLogInEmailError('');
        }
         else {
            event.preventDefault();
            setLogInEmailError('');
            setLogInPasswordError('');
        try{
                const response = await fetch('/api/logIn', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({logInEmail,logInPassword})
                });
                const data = await response.json();
                if(data.message==='login successfull'){
                    setLogInEmailError('');
                    setLogInPasswordError('');
                    setIsNewTagsVisible(false);
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('loggedInEmail',logInEmail);
                    localStorage.setItem('loggedInPassword',logInPassword);
                    isSubscribed();
                }
                else if(data.message==='Please create account before Log In'){
                    setLogInEmailError(data.message);
                    setLogInPasswordError('');
                }else if(data.message==='password incorrect'){
                    setLogInEmailError('');
                    setLogInPasswordError(data.message);
                }
            } catch (error) {}
        }
    };

    
      const handlePasswordPaste = (e) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData('text');
        setLogInPassword(pastedValue);
      };
    

    const toggleEnterOTPVisible=()=>{
        setForgotEmail('');
        setEnterOTPVisible(!isEnterOTPVisible);
    }
    const toggleForgotPasswordVisible=()=>{
        setForgotPasswordEmailError('');
        setForgotEmail('');
        setForgotPasswordVisible(!isForgotPasswordVisible);
    }
    const toggleForgotPasswordOTPVisible=()=>{
        setEnteredOTP('');
        setEnterOTPVisibleForgotPass(!isEnterOTPVisibleForgotPass);
        setForgotPasswordVisible(true);
    }
    const toggleNewTagsVisibility = () => {
        setLogInEmail('');
        setSignUpEmail('');
        setForgotEmail('');
        setEnteredOTP('');

        setSignUpPassword('');
        setLogInPassword('');
        setNewPassword('');
        
        setLogInEmailError('');
        setLogInPasswordError('');
        setSignUpEmailError('');
        setSignUpPasswordError('');
        setForgotPasswordEmailError('');
        setForgotPasswordOTPError('');
        setNewPasswordError('');

        setCreatedAccountVisible(false);
        setEnterOTPVisible(false);
        setEnterOTPVisibleForgotPass(false);
        setForgotPasswordVisible(false);
        setEnterPasswordVisibleForgotPass(false);

        setIsNewTagsVisible(!isNewTagsVisible);
      };
    const toggleCreateNewAcountVisibility=()=>{
        setLogInEmail('');
        setSignUpEmail('');
        setSignUpPassword('');
        setLogInPassword('');
        setLogInEmail('');
        setSignUpEmail('');
        setLogInEmailError('');
        setLogInPasswordError('');
        setSignUpEmailError('');
        setSignUpPasswordError('');
        setCreatedAccountVisible(!isCreatedNewAccountVisible);
    }
    const toggleUserProfileVisible=()=>{
        setUserProfileVisible(!isUserProfileVisible);
        setIsDropdownOpen(false);
    }

    const toggleUserMessagesVisible=()=>{
        setUserMessagesVisible(!isUserMessagesVisible);
        setIsDropdownOpen(false);
    }

    const handleDivClick = () => {
        const fileInput = document.getElementById('profilePictureInput');
        if (fileInput) {
            fileInput.click();
        }
    };


    const loginwithgoogle = ()=>{
        const scopes = 'profile email';
        const redirectUri = 'https://adventroz.com/auth/google/callback';
        const clientId = '279815824253-e94pr6j8uq141o6sp6qm28skuo4p5s63.apps.googleusercontent.com';
        const responseType = 'code';
    
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}`;

        window.open(authUrl, "_self");
    }
     const saveProfilePictureToDtaBase=async (file)=>{
        const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
        try{
            const formData = new FormData();
            formData.append('file', file);
            formData.append('email', email);

            const result=await axios.post('/api/userprofilePicture',
                formData,
                {
                    headers:{"Content-Type":"multipart/form-data"}
                }
            )
            if(result.data==='legal document reset successfully'){
                
            }
            setVerifyButtonDisables(true);
        }catch (error) {}
     }
    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfilePicture(e.target.result);
            };
            reader.readAsDataURL(file);
            saveProfilePictureToDtaBase(file);
        }
    };

    const navigateToSubscription=(event)=>{
        event.preventDefault();
        handleSetActive('subscription')
        navigate({
            pathname: "/subscription",
        });
      }

      const navigateToTourPlans=(event)=>{
        event.preventDefault();
        handleSetActive('tourPlans')
        navigate({
            pathname: "/tourPlans",
        });
      }

      const navigateToTourGuideProfile=(event)=>{
        event.preventDefault();
        if(localStorage.getItem('loggedInEmailGoogle') || localStorage.getItem('isLoggedIn') ){
            console.log(localStorage.getItem('loggedInEmailGoogle'));
            console.log(localStorage.getItem('loggedInEmail'));
            handleSetActive('becomeAGuide');
            navigate({
                pathname: "/tourGuideProfile",
            });
        }else{
            setIsNewTagsVisible(true);
            setErrorMessage('please log in first');
        }
      }
    
    return (
        <nav className={`navbar`}>
            <div className="logo">
            <img src={logo} alt={"ADVENTROZ"}/>
            ADVENTROZ</div>
            <div  className={`nav-bar-container ${showLinks ? 'nav-bar-container-show' : ''}`}>
                <ul className="nav-links">
                    {(localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle')) && (<li style={{marginLeft:'25%'}} className='userInfoIconinMobileView' onClick={toggleDropdown}>
                        {profilePicture ? (
                                    <img src={profilePicture} alt="Profile" className="profilePicture" />
                                    ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-person-fill" viewBox="0 -1 12 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                    </svg>
                            )}
                    </li>)}
                    <li><Link to="home" smooth={true} duration={1000} className={activeLink === 'home' ? 'active' : ''} onClick={() => handleSetActive('home')}>Home</Link></li>
                    <li><Link to="explore" smooth={true} duration={1000} className={activeLink === 'explore' ? 'active' : ''} onClick={() => handleSetActive('explore')}>Explore</Link></li>
                    <li><Link to="subscription" smooth={true} duration={1000} className={activeLink === 'subscription' ? 'active' : ''} onClick={navigateToSubscription}> Subscription</Link></li>
                    <li><Link to="about" smooth={true} duration={1000} className={activeLink === 'about' ? 'active' : ''} onClick={() => handleSetActive('about')}>About Us</Link></li> 
                    {/* <li><Link to="about" smooth={true} duration={1000} className={activeLink === 'tourPlans' ? 'active' : ''} onClick={navigateToTourPlans}>Tour Plans</Link></li>*/}
                    <li><Link to="becomeAGuide" smooth={true} duration={1000} className={activeLink === 'becomeAGuide' ? 'active' : ''} onClick={navigateToTourGuideProfile}>ADVENTROZ Guide</Link></li>
                </ul>
                {!(localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle')) && (<button onClick={toggleNewTagsVisibility}>Log In | Sign Up</button>)}
                {(localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle')) && (
                <div className='userInfoIcon' onClick={toggleDropdown}>
                {profilePicture ? (
                            <img src={profilePicture} alt="Profile" className="profilePicture" />
                            ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-person-fill" viewBox="0 -1 10 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                            </svg>
                    )}
                </div>)
                }{isDropdownOpen && (
                    <div className='dropdownMenu' ref={menuRef}>
                        <ul>
                        <li className='specialCase' style={{display:'flex',flexDirection:'column',backgroundColor:'white',position:'relative',zIndex:'1000'}}>
                            <div style={{margin:'20px',position:'relative',display:'block'}} className='userInfoIcon' onClick={handleDivClick}>
                                {profilePicture ? (
                                            <img src={profilePicture} alt="Profile" className="profilePicture" />
                                            ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-person-fill" viewBox={(screenWidth>1060)?"0 -1 10 16":"0 -1 12 16"}>
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                            </svg>
                                    )}
                                </div>
                                <input type="file" id="profilePictureInput" accept="image/*" style={{display:'none'}} onChange={handleProfilePictureChange}/>
                                <hr style={{backgroundColor:'grey', width:'100%',marginBottom:'-10px'}}></hr>
                            </li>
                            <li onClick={toggleUserProfileVisible}> 
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '25px', height: '25px' }} fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                </svg>
                                <span>Edit Profile</span>
                            </li>
                            <li onClick={toggleUserMessagesVisible}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '25px', height: '25px' }} fill="currentColor" class="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
                                </svg>
                                <span>Messages</span>
                            </li>
                            <li onClick={handleLogout} id='logout'>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '25px', height: '25px' }} fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                                    <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                                </svg>
                                <span>Logout</span>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <button onClick={toggleLinks} className="menu-icon">&#9776;</button>
            {isUserProfileVisible && (
                <UserProfile setUserProfileVisible={setUserProfileVisible}/>
            )}
            {isUserMessagesVisible && (
                <NotificationPanel isclose={()=>setUserMessagesVisible(false)}/>
            )}
            {isAboutUsVisible && (
                <AboutUs setIsAboutUsVisible={setIsAboutUsVisible}/>
            )}
            {isNewTagsVisible && (
            <div className='loginAndSignupPopupWindow'>
                <div className='logInAndSignUp'>
                    <div className='leftSide'/>
                    {!isCreatedNewAccountVisible && !isEnterOTPVisible && !isForgotPasswordVisible && !isEnterOTPVisibleForgotPass  && !isEnterPasswordVisibleForgotPass &&(
                    <div className='rightSideLogIn'>
                        <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                            <h2>ADVENTROZ</h2>
                            <form onSubmit={handleSubmitForSignIn}>
                                <input type="text" value={logInEmail} onChange={(e)=>setLogInEmail(e.target.value)} onPaste={(e)=>setLogInEmail(e.clipboardData.getData('text'))} id="username" name="username" placeholder="Enter Email : " required/>
                                <p className='errorInEmail'>{logInEmailError}<br></br></p>
                                <input type="password" value={logInPassword} onChange={(e)=>setLogInPassword(e.target.value)} onPaste={(e)=>setLogInPassword(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter Password :" required/>
                                <p className='errorInPassword'>{logInPasswordError}<br></br></p>
                                <br/>
                                <span className='forgotPassword' onClick={toggleForgotPasswordVisible}>Forgot Password?</span>
                                <br/>
                                <input className='loginButton' type="submit" value="Sign In"/>
                            </form>
                            <div className='orStatement'><div className='orLineleft'></div><p>or</p><div className='orLineright'></div></div>
                            <div style={{fontSize:(screenWidth>"470px")?"17px":"14px"}} className='loginButtonGoogle' onClick={loginwithgoogle}>
                                    <div className='google-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height={screenWidth>"480px"?"40":"30"} width={screenWidth>"480px"?"40":"35"} viewBox={screenWidth>"480px"?"0 0 396667 503333":"0 0 426667 333333"} shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                                            <path d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z" fill="#4285f4"/>
                                            <path d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z" fill="#34a853"/>
                                            <path d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z" fill="#fbbc04"/>
                                            <path d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z" fill="#ea4335"/>
                                        </svg>
                                    </div>
                                Sign In With Google
                            </div>
                            <p className='areYouNew'>Are You new? <span onClick={toggleCreateNewAcountVisibility} className='createNewAccountLink'>Create New Account</span></p>
                        </div>
                    )}
                    
                    {isCreatedNewAccountVisible &&(
                            <div className='rightSideLogIn'>
                                <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                                    <h2>ADVENTROZ</h2>
                                    <form onSubmit={handleSubmitForSignUp}>
                                        <input type="text" value={signUpEmail} onChange={(e)=>setSignUpEmail(e.target.value)} onPaste={(e)=>setSignUpEmail(e.clipboardData.getData('text'))} id="username" name="username" placeholder="Enter Email" required/>
                                        <p className='errorInEmail'>{signUpEmailError}<br></br></p>
                                        <input type="password" value={signUpPassword} onChange={(e)=>setSignUpPassword(e.target.value)} onPaste={(e)=>setSignUpEmail(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter Password" required/>
                                        <p className='errorInPassword'>{signUpPasswordError}<br></br></p>
                                        <br></br>
                                        <input className='loginButton' type="submit" value="Sign Up"/>
                                    </form>
                                    <br></br>
                                    <div className='orStatement'><div className='orLineleft'></div><p>or</p><div className='orLineright'></div></div>
                                    
                                    <div style={{fontSize:(screenWidth>"470px")?"17px":"14px"}} className='loginButtonGoogle' onClick={loginwithgoogle}>
                                    <div className='google-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height={screenWidth>"480px"?"40":"30"} width={screenWidth>"480px"?"40":"35"} viewBox={screenWidth>"480px"?"0 0 396667 503333":"0 0 426667 333333"} shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                                            <path d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z" fill="#4285f4"/>
                                            <path d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z" fill="#34a853"/>
                                            <path d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z" fill="#fbbc04"/>
                                            <path d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z" fill="#ea4335"/>
                                        </svg>
                                    </div>
                                    Sign Up with Google
                                </div>
                                <p className='areYouNew'>Already Have Account? <span onClick={toggleCreateNewAcountVisibility} className='createNewAccountLink'>Sign In</span></p>
                            </div>
                    )}
                    {!isCreatedNewAccountVisible && isEnterOTPVisible &&(
                            <div className='rightSideLogIn'>
                                 <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                                    <h2>ADVENTROZ</h2>
                                    <form onSubmit={handleSubmitOTP}>
                                        <input type="password" value={enteredOTP} onChange={(e)=>setEnteredOTP(e.target.value)} onPaste={(e)=>setEnteredOTP(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter OTP :" required/>
                                        <p className='errorInPassword'>{signUpOTPError}<br></br></p>
                                        <br></br>
                                        <input className='loginButton' type="submit" value="Submit OTP"/>
                                    </form>
                                    <br></br>
                                <p className='areYouNew'>Didn't get the OTP? <span onClick={toggleEnterOTPVisible} className='createNewAccountLink'>re-check email</span></p>
                            </div>
                    )}
                    {isForgotPasswordVisible &&(
                            <div className='rightSideLogIn'>
                                <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                                    <br></br><br></br>
                                    <h2>ADVENTROZ</h2>
                                    <br></br><br></br>
                                    <form onSubmit={handleSubmitForForgotPassword}>
                                        <input type="text" value={forgotEmail} onChange={(e)=>setForgotEmail(e.target.value)} onPaste={(e)=>setForgotEmail(e.clipboardData.getData('text'))} id="username" name="username" placeholder="Enter Email : " required/>
                                        <p className='errorInEmail'>{forgotPasswordEmailError}<br></br></p>
                                       <br></br>
                                        <input className='loginButton' type="submit" value="Send OTP"/>
                                    </form>
                                    <br></br>
                                    
                                <p className='backToSignInFromForgotPass'> Go Back to Sign in? <span onClick={toggleForgotPasswordVisible} className='createNewAccountLink'>Sign In</span></p>
                            </div>
                    )}
                    {isEnterOTPVisibleForgotPass &&(
                            <div className='rightSideLogIn'>
                                 <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                                    <h2>ADVENTROZ</h2>
                                    <form onSubmit={handleSubmitOTPForForgotPsssword}>
                                        <input type="password" value={enteredOTP} onChange={(e)=>setEnteredOTP(e.target.value)} onPaste={(e)=>setEnteredOTP(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter OTP :" required/>
                                        <p className='errorInPassword'>{forgotPasswordOTPError}<br></br></p>
                                        <br></br>
                                        <input className='loginButton' type="submit" value="Submit OTP"/>
                                    </form>
                                    <br></br>
                                <p className='areYouNew'>Didn't get the OTP? <span onClick={toggleForgotPasswordOTPVisible} className='createNewAccountLink'>re-check email</span></p>
                            </div>
                    )}
                    {isEnterPasswordVisibleForgotPass &&(
                            <div className='rightSideLogIn'>
                                 <div onClick={toggleNewTagsVisibility} className='closeLoginAndSignUpPopUp'>X</div>
                                    <h2>ADVENTROZ</h2>
                                    <form onSubmit={handleSubmitForNewPassword}>
                                        <input type="password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} onPaste={(e)=>setNewPassword(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter Password :" required/>
                                        <p className='errorInPassword'>{newPasswordError}<br></br></p>
                                        <br></br>
                                        <input className='loginButton' type="submit" value="Reset Password"/>
                                    </form>
                                    <br></br>
                            </div>
                    )}
                </div>
            </div>
        )}
        {errorMessage!=='' && (<ErrorMessage message={errorMessage} onClose={()=>setErrorMessage('')}/>)}
        </nav>
    );
};

export default NavBar;